import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Famille, Input } from './famille';

@Injectable({ providedIn: 'root' })
export class CalculatorService {

  url: string = 'api/calculator';
  printPdfUrl: string = 'api/calculator/pdf';

  constructor(private http: HttpClient) {
  }

  ngOnInit() {
  }

  printPdfFamille(inp: Input, year: number): Observable<any> {
    return this.http.post<any>(this.printPdfUrl + '/' + year + '/famille', inp, { responseType: 'blob' as 'json' });
  }

  printPdfComite(inp: Input, year: number): Observable<any> {
    return this.http.post<any>(this.printPdfUrl + '/' + year + '/comite', inp, { responseType: 'blob' as 'json' });
  }

  calculate(inp: Input, year: number): Observable<Famille> {
    return this.http.post<Famille>(this.url + '/' + year, inp);
  }
}
