import {Parameters} from './parameters';

export class Famille {
  params: Parameters;
  input: Input;
  output: Output;
}

export class Input {
  familleName: string;
  rentree: number;
  diProvided: boolean;

  code820: number = 0;
  code410: number = 0;

  anneeArrivee: number = 0;

  nb_1_2_3H: number = 0;
  nb_4_5_6_7_8: number = 0;
  nb_9_10_11: number = 0;
}

export class Output {
  ecolageMax: number = 0;
  ecolageMin: number = 0;

  capacityY: number = 0;
  capacityM: number = 0;

  ecolageSurCapa: number;

  percentFidelite: number = 0;
  rabaisFidelite: number = 0;
  ecolageEffAfterFidelite: number = 0;

  ecolageEff: number = 0;
}
