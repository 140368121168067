import { Component, OnInit } from '@angular/core';
import { Famille, Input, Output } from './famille';
import { ActivatedRoute } from '@angular/router';
import { CalculatorService } from './calculator.service';
import { Parameters } from './parameters';
import { saveAs } from 'file-saver';

@Component({
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit {
  private PASSWORD = 'CestQuePourNous';

  passwordReady = false;
  pageReady = false;
  diNotProvided = false;

  rentree = 2024;

  static PALIER1_LABEL = '1-3H';
  static PALIER2_LABEL = '4-8H';
  static PALIER3_LABEL = '9-11H';

  static PALIER0: Palier;
  static PALIER1: Palier;
  static PALIER2: Palier;
  static PALIER3: Palier;
  paliers: Palier[];

  params: Parameters;
  output: Output;
  familleName: string;

  // Situation
  revenuStr: string = '';
  fortuneStr: string = '';
  anneesStr: string = '';

  enfants: Enfant[] = [];


  constructor(private route: ActivatedRoute,
              private calculatorService: CalculatorService) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      //console.log("Params", params);
      let password: string = params.password;
      if (password != this.PASSWORD) {
        console.log('Redirection sur index.html');
        window.open('https://www.labergerie.ch', '_self');
      } else {
        console.log('pageReady!');
        this.passwordReady = true;
      }
    });

    CalculatorComponent.PALIER0 =
      {
        label: '---',
        max: 0,
        min: 0,
      };
    CalculatorComponent.PALIER1 =
      {
        label: CalculatorComponent.PALIER1_LABEL,
        max: 800,
        min: 500,
      };

    CalculatorComponent.PALIER2 =
      {
        label: CalculatorComponent.PALIER2_LABEL,
        max: 1000,
        min: 600,
      };
    CalculatorComponent.PALIER3 =
      {
        label: CalculatorComponent.PALIER3_LABEL,
        max: 1200,
        min: 650,
      };

    this.paliers = [
      CalculatorComponent.PALIER0,
      CalculatorComponent.PALIER1,
      CalculatorComponent.PALIER2,
      CalculatorComponent.PALIER3,
    ];

    this.enfants[0] = new Enfant();
    this.enfants[0].palier = CalculatorComponent.PALIER1;
    this.enfants[1] = new Enfant();
    //this.enfants[1].palier = CalculatorComponent.PALIER2;
    this.enfants[2] = new Enfant();
    this.enfants[3] = new Enfant();

    console.log('init', this.enfants);
    this.calculate();
  }

  changePalier($event, index: number) {
    const str = $event.target.value;
    console.log('changePalier', str);
    let p: Palier;
    if (str === CalculatorComponent.PALIER0.label) {
      p = CalculatorComponent.PALIER0;
    }
    if (str === CalculatorComponent.PALIER1.label) {
      p = CalculatorComponent.PALIER1;
    }
    if (str === CalculatorComponent.PALIER2.label) {
      p = CalculatorComponent.PALIER2;
    }
    if (str === CalculatorComponent.PALIER3.label) {
      p = CalculatorComponent.PALIER3;
    }
    this.enfants[index].palier = p;

    this.calculate();
  }

  createInput(): Input {
    let input = new Input();
    input.familleName = this.familleName;
    input.diProvided = !this.diNotProvided;
    input.rentree = this.rentree;
    input.anneeArrivee = +this.anneesStr;
    input.code820 = +this.revenuStr;
    input.code410 = +this.fortuneStr;
    console.log('revenuStr1', input.code820);
    console.log('revenuStr2', input.code410);

    for (let e of this.enfants) {
      if (e.palier.label == CalculatorComponent.PALIER1_LABEL) {
        input.nb_1_2_3H++;
      }
      if (e.palier.label == CalculatorComponent.PALIER2_LABEL) {
        input.nb_4_5_6_7_8++;
      }
      if (e.palier.label == CalculatorComponent.PALIER3_LABEL) {
        input.nb_9_10_11++;
      }
    }
    return input;
  }

  printPdfTitle(): string {
    return this.canPrintPdf() ? '' : 'Le nom de famille est obligatoire';
  }

  canPrintPdf(): boolean {
    return this.familleName && this.familleName.length > 2;
  }

  printPdfFamille() {
    const input = this.createInput();
    console.log('printPdf in', input);
    this.calculatorService.printPdfFamille(input, this.rentree)
      .subscribe(data => {
          console.log('pdf', data);
          let blob = new Blob([data], { type: 'application/pdf;charset=utf-8' });
          saveAs(blob, `${this.familleName}-ecolage-${this.rentree}.pdf`);
        }, error => {
          console.log(error);
        }
      );
  }

  printPdfComite() {
    const input = this.createInput();
    console.log('printPdf in', input);
    this.calculatorService.printPdfComite(input, this.rentree)
      .subscribe(data => {
          console.log('pdf', data);
          let blob = new Blob([data], { type: 'application/pdf;charset=utf-8' });
          saveAs(blob, `${this.familleName}-comite-${this.rentree}.pdf`);
        }, error => {
          console.log(error);
        }
      );
  }

  calculate() {
    const input = this.createInput();
    console.log('calculate in', input);
    this.calculatorService.calculate(input, this.rentree).subscribe((data: Famille) => {
      this.output = data.output;
      this.params = data.params;
      this.pageReady = this.passwordReady;
      console.log('calculate params', this.params);
      console.log('calculate output', this.output);
      console.log('calculate pageReady', this.pageReady);
    });
  }

  inputValueChange($event) {
    this.calculate();
  }

  tooltipRevenuDeterminant = 'Le revenu total déterminant de votre famille, relevé sous le chiffre 820 de votre déclaration';
  tooltipFortuneNumeraire = 'La fortune nette en uméraire de votre famille. Il s\'agit de la somme des 2 colonnes du chiffre 410 de votre déclaration';
  tooltipNombreAnneesEcole = 'L\'année où l\'un de vos enfants a commencé à fréquenter l\'école';
  tooltipAnneeScolaire = 'L\'année scolaire dans laquelle votre enfant sera enclassé';
  tooltipEcolageEnfantN = 'L\'écolage de base dû pour cet enfant. Un soutien pourra être accordé (ci-dessous) pour chaque enfant si votre situation financière le permet';
  tooltipEcolageMaxFamille = 'La somme de l\'écolage de base pour votre famille, avant un éventuel soutien';
  tooltipEcolageMinFamille = 'La somme de l\'écolage minimum pour votre famille';
  tooltipSoutienMaxFamille = 'Le soutien maximum que l\'école peut octroyer si votre situation financière le permet';
  tooltipCapaciteFinanciere = 'La capacité financière maximum de votre famille (annuelle et mensuelle), retenue selon les critères de votre ' +
    'déclaration d\'impôts et votre situation de famille';
  tooltipTotalAvantRabais = 'Ecolage après déduction du soutien, avant rabais';
  tooltipRabaisPlusieursEnfants = 'Rabais accordé pour l\'enclassement de plusieurs enfants de la même famille';
  tooltipRabaisFidelite = 'Rabais de fidélité à l\'école';
}

export class Enfant {
  palier: Palier = CalculatorComponent.PALIER0;
}

export class Palier {
  label: string;
  max: number = 0;
  min: number = 0;
}
